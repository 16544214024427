import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectsGalleryContent from "../components/GalleryContent/ProjectsGalleryContent"

const ProjectsGallery = () => (
  <Layout>
    <SEO title="Cleanse" />
    <ProjectsGalleryContent />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default ProjectsGallery
